export const KEYS = {
  ACCESS_TOKEN: 'access_token',
  ADDITIONAL_DAYS: 'additional_days',
  ACTIONS: 'actions',
  ACTIVE_FILTERS: 'activeFilters',
  ACTIVE_SORT: 'activeSort',
  ACTIVE: 'active',
  ADD: 'add',
  ALL_PAGE: 'allPage',
  ALL_SELECTED: 'allSelected',
  ALL: 'all',
  ALLERGEN: 'allergen',
  AMOUNT: 'amount',
  ARCHIVED: 'archived',
  ARTICLE_CODE: 'article_code',
  ASCENDING: 'asc',
  ASSORTMENT: 'assortment',
  BLOCKED: 'blocked',
  BOXES: 'boxes',
  BANANAS: 'bananas',
  BTW: 'btw',
  BUY: 'buy',
  CATEGORY_WEBSHOP: 'category_webshop',
  CATEGORY: 'category',
  CATEGORY_ID: 'category_id',
  CITY: 'city',
  COLOR: 'color',
  CONFIRM: 'confirm',
  CONTACTS: 'contacts',
  COMPANIES: 'companies',
  CUSTOMER_PROFILE: 'customer_profile',
  CREATED_AT: 'created_at',
  CUSTOMERS: 'customers',
  CUSTOMER: 'customer',
  CUSTOMER_ID: 'customer_id',
  DAYS: 'days',
  DATA_GRID: 'datagrid',
  DATA: 'data',
  DELIVERIES: 'deliveries',
  DELIVERY_RECEIPT: 'receives_tickets',
  DESCENDING: 'desc',
  EMAIL: 'email',
  EMPLOYEE: 'employee',
  EMPLOYEES: 'employees',
  EXTRA: 'extra',
  EXTRA_INFORMATION: 'extra_information',
  FIELDS: 'fields',
  FILTERS: 'filters',
  FORM: 'form',
  FRUIT: 'fruit',
  FIRST_CREATED_AT: 'first_created_at',
  FIRST_NAME: 'first_name',
  HAS_CUSTOM_AVAILABILITY: 'has_custom_availability',
  ID: 'id',
  INFO: 'info',
  INGREDIENTS: 'ingredients',
  INTENDED: 'intended',
  INVOICE_ARTICLE_ID: 'invoice_article_id',
  INVOICE_ARTICLE: 'invoice_article',
  IMAGE: 'image',
  IS_BUSY: 'isBusy',
  IS_PRIMARY: 'is_primary',
  JOINS: 'joins',
  LAST_NAME: 'last_name',
  LOADING: 'loading',
  LOCATIONS: 'locations',
  LP: 'lp',
  LUNCH: 'lunch',
  LUNCHERS: 'lunchers',
  MAIL: 'mail',
  MARGE: 'marge',
  MIDDLE_NAME: 'middle_name',
  MODAL: 'modal',
  WEBSHOP_ACTIVE: 'webshop_active',
  WEBSHOP_NAME: 'webshop_name',
  WEBSHOP_DESCRIPTION: 'webshop_description',
  WEBSHOP_SALE: 'webshop_sale',
  NAME_SHORT: 'name_short',
  NAME_WEBSHOP: 'name_webshop',
  NAME: 'name',
  NEXT: 'next',
  NEW_TOKEN: 'new_token',
  NONE: 'none',
  NUMBER: 'number',
  OBJECT: 'object',
  PAGE: 'page',
  PARAMS: 'params',
  PAGINATION: 'pagination',
  PHONE: 'phone',
  POSTALCODE: 'postalcode',
  PREVIOUS: 'prev',
  PROFILE: 'profile',
  PRODUCT_AVAILABILITY: 'product_availability',
  PRODUCT_CATEGORIES: 'product_categories',
  PRODUCT_GROUP_ID: 'product_group_id',
  PRODUCT_GROUP: 'product_group',
  PRODUCT_GROUPS: 'product_groups',
  PRODUCT_INFORMATION: 'product_information',
  PRODUCT_LOCATION: 'product_location',
  PRODUCT_WEBSHOP_EXTRAS: 'product_webshop_extras',
  PRODUCT_SELECTOR: 'product_selector',
  PRODUCT: 'product',
  PRODUCTS: 'products',
  PRODUCT_TAGS: 'product_tags',
  PRODUCT_TAGS_DIET: 'product_tags_diet',
  PRODUCT_TAGS_ALLERGIES: 'product_tags_allergies',
  PRODUCT_TAGS_FACILITY: 'product_tags_facility',
  PROFILE_TAGS: 'profile_tags',
  QUERY: 'query',
  RACK: 'rack',
  REMOVE: 'remove',
  RESET: 'reset',
  RESULTS: 'results',
  RETOUR: 'retour',
  ROLES: 'roles',
  ROLES_EMPLOYEE: 'roles_employee',
  ROLES_CONTACT: 'roles_contact',
  SALAD: 'salad',
  SALE_WEBSHOP: 'sale_webshop',
  SALE: 'sale',
  SAVED_PROFILE: 'saved_profile',
  SAVED_PRODUCT: 'saved_product',
  SAVED_SUPPLIER: 'saved_supplier',
  SEARCH: 'search',
  SELECTED_VALUES: 'selectedValues',
  SHELVE: 'shelve',
  SINGLE: 'single',
  SITE_ID: 'site_id',
  SIZE: 'size',
  SOUP: 'soup',
  SPACE_ID: 'space_id',
  STREET: 'street',
  SUPPLIER_CONTACTS: 'supplier_contacts',
  SUPPLIER_INFORMATION: 'supplier_information',
  SUPPLIER_WEBSHOP: 'supplier_webshop',
  SUPPLIER: 'supplier',
  SUPPLIERS: 'suppliers',
  TEMPLATES: 'templates',
  TEMPLATE: 'template',
  TODO: 'todo',
  TOKEN: 'token',
  UNAUTH: 'unauth',
  UNIT: 'unit',
  UPDATED_AT: 'updated_at',
  VOLUME_MEASUREMENT: 'volume_measurement',
  VOLUME: 'volume',
  VALIDATE: 'validate',
  VALUE: 'value',
  WEEK: 'week',
  WEEKS: 'weeks',
  WEBSHOP: 'webshop',
  WEEK_ORDER: 'week_order',
  LUNCH_BUDGET: 'budget'
} as const

export const STORE_KEYS = {
  AUTH: 'auth',
  UI: 'ui',
  CUSTOMER: 'customer',
  PRODUCTS: 'products',
  SUPPLIERS: 'suppliers',
  INVOICES: 'invoices',
  GLOBAL_SEARCH: 'global_search',
  TAGS: 'tags',
  TEMPLATE: 'template',
  EMPLOYEES: 'employees',
  USERS: 'users',
  USERS_EMPLOYEES: 'users/employees',
  USERS_CONTACTS: 'users/contacts'
} as const

export const ROUTE_KEYS = {
  HOME: 'home',
  LOGIN: 'login',
  AUTH: 'auth',
  CUSTOMER_PROFILE: 'customer_profile',
  USER_LIST: 'user_list',
  USER_PROFILE: 'user_profile',
  USER_PROFILE_CREATE: 'user_profile_create',
  PRODUCT_LIST: 'product_list',
  PRODUCT_CREATE: 'product_create',
  PRODUCT_DETAIL: 'product_detail',
  PRODUCT_TAGS: 'product_tags',
  PRODUCT_TAGS_DETAILS: 'product_tags_detail',
  SUPPLIER_LIST: 'supplier_list',
  SUPPLIER_DETAIL: 'supplier_detail',
  SUPPLIER_CREATE: 'supplier_create',
}

export const SOCKET_KEYS = {
  TEMPLATE: 'buurtbase',
}
