import { useState, useMemo, useContext, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { observer } from 'mobx-react-lite'
import { AcFormContext, AcTypography, AcLunchDayRow } from '@components'
import { KEYS, LABELS, WEEKDAYS } from '@constants'
import type { IWeekdaysKeys, ICustomerProfileDayRow } from '@typings'
// eslint-disable-next-line
import styles from './ac-template-lunch-days-table.module.scss'

// 24px checkbox minus 8px gap as the first gap is 8px instead of 16px (1rem)
const GRID_TEMPLATE_COLS = '16px 5fr 3fr 3fr'

export interface IAcLunchDaysTable {

  showDietaryPreferences: boolean
}

// eslint-disable-next-line
export const AcLunchDaysTable = observer(({ showDietaryPreferences }: IAcLunchDaysTable) => {

  const formContext = useContext(AcFormContext)
  const { watch, setValue } = useFormContext()
  const register = formContext?.registerInput
  const [weekdayPayload, setWeekdayPayload] = useState<
    ICustomerProfileDayRow[]
  >([])

  const watchDays = watch('days')

  useEffect(() => {
    if (watchDays?.length && !weekdayPayload.length) {
      setWeekdayPayload(watchDays)
    }
  }, [watchDays])

  const onRowSelect = (checked: boolean, day: IWeekdaysKeys) => {
    if (!checked) {
      setWeekdayPayload(prev =>
        prev.filter((item: ICustomerProfileDayRow) => item.day !== day)
      )
    } else {
      setWeekdayPayload(prev => [
        ...prev,
        { day, preference: null, luncher_count: null },
      ])
    }
  }

  const onRowDataChange = ({
    day,
    preference,
    luncher_count,
  }: ICustomerProfileDayRow) => {
    const dayIndex = weekdayPayload.findIndex(weekday => weekday.day === day)
    if (dayIndex !== -1) {
      const list = weekdayPayload
      list[dayIndex] = { day, preference, luncher_count }
      // Use spread operator to create new Array reference, to trigger the useEffect
      setWeekdayPayload([...list])
    }
  }

  useEffect(() => {
      const filtered = weekdayPayload.filter(item => item.day)
      setValue(KEYS.DAYS, filtered)
  }, [weekdayPayload])

  useEffect(() => {
    register({ name: KEYS.DAYS, validate: undefined, label: KEYS.DAYS })
  }, [])


  const getWeekdayRows = useMemo(() => {
    const keys = Object.keys(WEEKDAYS)
    return keys.map((day, i) => (
      <AcLunchDayRow
        key={i}
        index={i}
        day={day as IWeekdaysKeys}
        label={WEEKDAYS[day]}
        GRID_TEMPLATE_COLS={GRID_TEMPLATE_COLS}
        readOnly={formContext?.readOnly}
        initial={weekdayPayload.find(weekday => weekday.day === day)}
        showDietaryPreferences={showDietaryPreferences}
        onRowSelect={onRowSelect}
        onRowDataChange={onRowDataChange}
      />
    ))
  }, [WEEKDAYS, formContext?.readOnly, weekdayPayload, showDietaryPreferences, watchDays])

  return (
    <div className={styles['ac-template-lunch-days-table']}>
      <div
        className={styles['ac-template-lunch-days-table__header']}
        style={{ gridTemplateColumns: GRID_TEMPLATE_COLS }}>
        <div></div>
        <AcTypography
          color="neutral-700"
          size="sm">
          {LABELS.LUNCH_DAY}
        </AcTypography>
        {showDietaryPreferences ? (
          <AcTypography
            color="neutral-700"
            size="sm">
            {LABELS.MIX_VEGETARIAN}
          </AcTypography>
        ) : (<div></div>)}
        <AcTypography
          color="neutral-700"
          size="sm">
          {LABELS.LUNCHER_COUNT}
        </AcTypography>
      </div>
      <div className={styles['ac-template-lunch-days-table__body']}>
        {getWeekdayRows}
      </div>
    </div>
  )
})
